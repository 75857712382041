body {
  transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
  background-color: #f7f8fc;
  font-family: "Montserrat", "Roboto", sans-serif;
  color: #10182f;
}

.previous:hover {
  background-color: #ddd;
  color: black;
}

.previous {
  text-decoration: none;
  display: inline;
  position: absolute;
  z-index: 100;
  top: 3%;
  left: 6%;
  background-color: rgba(255, 255, 255, 0.25);
  color: rgba(255, 255, 255, 0.75);
  padding: 8px 20px;
  font-size: x-large;
  transform: translate(500px, 18px);
}

.round {
  border-radius: 50%;
  transform: scale(1.2);
}

.bigwrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.container {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.card {
  transition: height 0.5s;
  display: flex;
  margin: 0.9rem;
  background-color: #fff;
  border-radius: 2rem;
  box-shadow: 0 2rem 2rem rgba(0, 0, 0, 0.2);
  overflow: hidden;
  width: 80vw;
  height: 20rem;
  cursor: pointer;
}

.card-header {
  transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
  transition: max-width 0.3s;
  max-width: 30%;
}

.card-header img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.card-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  min-height: 250px;
}

.card-body {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 1s;
}

h4 {
  font-size: larger;
  font-family: "Montserrat", "Roboto", sans-serif;
}

.card-body p {
  font-size: 1rem;
  margin: 0 0;
}

.card:hover .ExpTitle {
  text-decoration: underline;
}
.card:hover .movingtext {
  top: 50%;
  transform: translateY(6em);
  font-size: large;
  align-self: center;
  opacity: 1;
  transition: 0.3s;
}

.movingtext {
  position: absolute;
  top: 50%;
  z-index: 1;
  font-weight: bold;
  transform: translateY(12em);
  opacity: 0;
  transition: 0.01s;
}

.card.active {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.card.active .card-header {
  width: 30%;
  opacity: 0.8;
  transition: opacity 1s;
}
.card.active .card-wrapper {
  max-width: 70%;
  transition: all 1s;
}
.card.active .card-body {
  transition: top 0.4s;
  top: 0%;
  transform: translate(-50%);
}
.card.active .movingtext {
  transition: all 0.03s;
  opacity: 0;
}

.additional {
  display: none;
  opacity: 0%;
  transition: all 0.3s;
}

.additional.active {
  display: block;
  padding-top: 1em;
  padding-bottom: 1em;
  opacity: 1;
}/*# sourceMappingURL=ExpCards.css.map */