@charset "UTF-8";
.mainContainer {
  width: 60%;
  margin: 0 auto;
  font-family: "Montserrat", "Roboto", sans-serif;
}

a {
  font-family: "Montserrat", "Roboto", sans-serif;
  color: #333;
  font-size: 1vw;
}

.glassBio {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.75);
}

.picNamC {
  display: flex;
  align-items: center;
}

.profilePic {
  border-radius: 50%;
  width: 10vw;
  height: 10vw;
  -o-object-fit: cover;
     object-fit: cover;
  margin-right: 1em;
  border-radius: 50%;
}

.NameContainer {
  display: flex;
  flex-direction: column;
}

.lastName {
  font-weight: bold;
  margin-bottom: 0px;
  font-size: 2vw;
  margin-top: 0%;
  display: inline-block;
}

.titles {
  font-weight: lighter;
  margin-bottom: 0px;
  font-size: 2vw;
  margin-top: 0%;
  display: inline-block;
}

.firstName {
  font-weight: bold;
  color: #333;
  font-size: 4vw;
  margin-top: 0%;
  margin-bottom: 0%;
}

.containerForBioCards {
  margin-top: 20px;
  display: inline-flex;
  flex-direction: row-reverse;
  margin-left: 12%;
}

.bioCard {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding-inline: 1rem;
  text-align: center;
  font-size: 1vw;
}

.iconForBio {
  margin-right: 0%;
  font-size: larger;
}

.iconForBio2 {
  margin-right: 0;
  font-size: larger;
}

.iconForBio3 {
  margin-right: 0%;
}

.iconForBio::before {
  content: "📧";
  margin-right: 5%;
  font-size: 1.5vw;
}

.iconForBio2::before {
  content: "🌐";
  margin-right: 5%;
  font-size: 1.5vw;
}

.iconForBio3::before {
  content: "📍";
  margin-right: 5%;
  font-size: 1.5vw;
}

@media (max-width: 900px) {
  .containerForBioCards {
    margin-top: 20px;
    flex-direction: column-reverse;
  }
  .bioCard {
    font-size: 3vw;
    margin-top: 2vw;
  }
  a {
    font-size: 3vw;
  }
  .mainContainer {
    width: 80%;
  }
  .iconForBio2::before, .iconForBio::before, .iconForBio3::before {
    margin-right: 0%;
    font-size: 3vw;
  }
  .firstName {
    font-size: 6vw;
  }
  .lastName {
    font-size: 4vw;
  }
  .titles {
    font-size: 4vw;
  }
  .profilePic {
    width: 18vw;
    height: 18vw;
    -o-object-fit: cover;
       object-fit: cover;
  }
}/*# sourceMappingURL=CBPage.css.map */