@charset "UTF-8";
.myName {
  margin-bottom: 5rem;
  margin-top: 1rem;
  font-size: xx-large;
  font-weight: bold;
  color: #E6E9ED;
  letter-spacing: 0.2rem;
  text-align: center;
}
.myName .subName {
  text-align: center;
  font-size: small;
  font-weight: lighter;
  color: #E6E9ED;
  letter-spacing: 0.2rem;
}

a:link {
  text-decoration: none;
}

a:hover {
  text-shadow: 0.9rem 0.9rem 0.5rem rgba(0, 0, 0, 0.2);
}

a {
  transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
}

link:visited {
  text-decoration: none;
}

link:hover {
  text-decoration: none;
}

link:active {
  text-decoration: none;
}

.option {
  background-repeat: no-repeat;
  position: relative;
  min-width: 60px;
  max-width: 1000px;
  margin-left: 1rem;
  margin-right: 1rem;
  background: var(--optionBackground, var(--defaultBackground, #E6E9ED));
  overflow: hidden;
  background-size: auto 120%;
  background-position: center;
  cursor: pointer;
  transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
}
.option:not(.active) {
  flex-grow: 1;
  border-radius: 5rem;
}
.option:not(.active) .shadow {
  box-shadow: rgba(0, 0, 0, 0.45) 0px -10rem 10rem -1rem inset;
}
.option .shadow {
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 100%;
  transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
}
.option .label {
  position: absolute;
  left: 50%;
  top: 75%;
  transform: translate(-50%, -50%);
}
.option .label > div {
  text-align: center;
}
.option .label .main {
  font-weight: bold;
  font-size: large;
  color: white;
}
.option .label .sub {
  font-size: medium;
  color: white;
}

.option:hover {
  box-shadow: rgba(0, 0, 0, 0.55) 0px 3rem 3rem;
  transform: scale(1.09);
}
.option:hover .shadow {
  box-shadow: rgba(0, 0, 0, 0.75) 0px -20rem 10rem -1rem inset;
}

.option:active {
  box-shadow: rgba(243, 216, 175, 0.788) 0px 3rem 3rem;
}

@-webkit-keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
body {
  background: linear-gradient(180deg, #396189 0%, #43A2B1 99.48%) no-repeat center center fixed;
  margin: 0;
  caret-color: transparent;
  font-family: "Montserrat", "Roboto", sans-serif;
  transition: 0.25s;
}

.homePageWrapper {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: 100vh;
}

.options {
  display: flex;
  min-width: 60rem;
  max-width: 120rem;
  width: calc(100% - 100px);
  height: 75%;
}
@media screen and (max-width: 860px) {
  .options {
    transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
    min-width: 860px;
  }
}
@media screen and (max-width: 820px) {
  .options {
    transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
    min-width: 820px;
  }
}
@media screen and (max-width: 780px) {
  .options {
    transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
    min-width: 780px;
  }
}
@media screen and (max-width: 740px) {
  .options {
    transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
    min-width: 740px;
  }
}
@media screen and (max-width: 700px) {
  .options {
    transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
    min-width: 700px;
  }
}
@media screen and (max-width: 660px) {
  .options {
    transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
    min-width: 660px;
  }
}

@media (max-width: 900px) {
  .options {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
    transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
    transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
    transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
    transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
    transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
    transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
    transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
    transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
    transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
  }
  .options .option {
    margin: 1rem;
  }
}
@media screen and (max-width: 900px) and (max-width: 960px) {
  .options {
    min-width: 755px;
  }
  .options .logo {
    transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
    height: 10rem- 0.35rem;
  }
  .options .label {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
  }
}
@media screen and (max-width: 900px) and (max-width: 920px) {
  .options {
    min-width: 710px;
  }
  .options .logo {
    transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
    height: 10rem- 0.7rem;
  }
  .options .label {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
  }
}
@media screen and (max-width: 900px) and (max-width: 880px) {
  .options {
    min-width: 665px;
  }
  .options .logo {
    transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
    height: 10rem- 1.05rem;
  }
  .options .label {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
  }
}
@media screen and (max-width: 900px) and (max-width: 840px) {
  .options {
    min-width: 620px;
  }
  .options .logo {
    transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
    height: 10rem- 1.4rem;
  }
  .options .label {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
  }
}
@media screen and (max-width: 900px) and (max-width: 800px) {
  .options {
    min-width: 575px;
  }
  .options .logo {
    transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
    height: 10rem- 1.75rem;
  }
  .options .label {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
  }
}
@media screen and (max-width: 900px) and (max-width: 760px) {
  .options {
    min-width: 530px;
  }
  .options .logo {
    transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
    height: 10rem- 2.1rem;
  }
  .options .label {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
  }
}
@media screen and (max-width: 900px) and (max-width: 720px) {
  .options {
    min-width: 485px;
  }
  .options .logo {
    transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
    height: 10rem- 2.45rem;
  }
  .options .label {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
  }
}
@media screen and (max-width: 900px) and (max-width: 680px) {
  .options {
    min-width: 440px;
  }
  .options .logo {
    transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
    height: 10rem- 2.8rem;
  }
  .options .label {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
  }
}
@media screen and (max-width: 900px) and (max-width: 640px) {
  .options {
    min-width: 395px;
  }
  .options .logo {
    transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
    height: 10rem- 3.15rem;
  }
  .options .label {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
  }
}
@media screen and (max-width: 900px) and (max-width: 600px) {
  .options {
    min-width: 350px;
  }
  .options .logo {
    transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
    height: 10rem- 3.5rem;
  }
  .options .label {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
  }
}
@media screen and (min-width: 1100px) {
  .options {
    min-width: 60rem;
    transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
  }
  .options .logo {
    height: 10rem;
    transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
  }
  .options .info {
    top: 75%;
  }
  .options .info .main {
    font-size: 1.12rem;
  }
  .options .info .sub {
    font-size: 1rem;
  }
}
@media screen and (min-width: 1140px) {
  .options {
    min-width: 63.5rem;
    transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
  }
  .options .logo {
    height: 10.5rem;
    transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
  }
  .options .info {
    top: 72%;
  }
  .options .info .main {
    font-size: 1.16rem;
  }
  .options .info .sub {
    font-size: 1.02rem;
  }
}
@media screen and (min-width: 1180px) {
  .options {
    min-width: 67rem;
    transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
  }
  .options .logo {
    height: 11rem;
    transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
  }
  .options .info {
    top: 69%;
  }
  .options .info .main {
    font-size: 1.2rem;
  }
  .options .info .sub {
    font-size: 1.04rem;
  }
}
@media screen and (min-width: 1220px) {
  .options {
    min-width: 70.5rem;
    transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
  }
  .options .logo {
    height: 11.5rem;
    transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
  }
  .options .info {
    top: 66%;
  }
  .options .info .main {
    font-size: 1.24rem;
  }
  .options .info .sub {
    font-size: 1.06rem;
  }
}
@media screen and (min-width: 1260px) {
  .options {
    min-width: 74rem;
    transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
  }
  .options .logo {
    height: 12rem;
    transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
  }
  .options .info {
    top: 63%;
  }
  .options .info .main {
    font-size: 1.28rem;
  }
  .options .info .sub {
    font-size: 1.08rem;
  }
}
@media screen and (min-width: 1300px) {
  .options {
    min-width: 77.5rem;
    transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
  }
  .options .logo {
    height: 12.5rem;
    transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
  }
  .options .info {
    top: 60%;
  }
  .options .info .main {
    font-size: 1.32rem;
  }
  .options .info .sub {
    font-size: 1.1rem;
  }
}
@media screen and (min-width: 1340px) {
  .options {
    min-width: 81rem;
    transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
  }
  .options .logo {
    height: 13rem;
    transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
  }
  .options .info {
    top: 57%;
  }
  .options .info .main {
    font-size: 1.36rem;
  }
  .options .info .sub {
    font-size: 1.12rem;
  }
}
@media screen and (min-width: 1380px) {
  .options {
    min-width: 84.5rem;
    transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
  }
  .options .logo {
    height: 13.5rem;
    transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
  }
  .options .info {
    top: 54%;
  }
  .options .info .main {
    font-size: 1.4rem;
  }
  .options .info .sub {
    font-size: 1.14rem;
  }
}
@media screen and (min-width: 1420px) {
  .options {
    min-width: 88rem;
    transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
  }
  .options .logo {
    height: 14rem;
    transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
  }
  .options .info {
    top: 51%;
  }
  .options .info .main {
    font-size: 1.44rem;
  }
  .options .info .sub {
    font-size: 1.16rem;
  }
}
@media screen and (min-width: 1460px) {
  .options {
    min-width: 91.5rem;
    transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
  }
  .options .logo {
    height: 14.5rem;
    transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
  }
  .options .info {
    top: 48%;
  }
  .options .info .main {
    font-size: 1.48rem;
  }
  .options .info .sub {
    font-size: 1.18rem;
  }
}
.logo {
  height: 10rem;
  transform: translate(-0.5rem, 1rem);
}

body::after {
  content: "© 2023 Abrar Patel";
  position: absolute;
  bottom: auto;
  left: 0;
  right: 0;
  margin-top: 5%;
  text-align: center;
  font-family: "Montserrat", "Roboto", sans-serif;
  font-weight: lighter;
  font-size: medium;
  color: white;
}/*# sourceMappingURL=MainCards.css.map */