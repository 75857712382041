
.myName{
   margin-bottom: 5rem;
   margin-top: 1rem;
   // padding-bottom: 0.5rem;
   font-size: xx-large;
   font-weight: bold;
   color: #E6E9ED;
   letter-spacing: 0.2rem;
   text-align: center;


   .subName{
      text-align: center;
      font-size: small;
      font-weight:lighter;
      color: #E6E9ED;
      letter-spacing: 0.2rem;
   }
   
}

a:link {
   text-decoration: none;
   
 }

 a:hover{
   
   text-shadow: 0.9rem 0.9rem  0.5rem  rgba(0,0,0,0.2);
   // text-shadow: 4px 3px 0px #fff, 9px 8px 0px rgba(0,0,0,0.15);
}

a{
   transition:.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
}
 
link:visited {
   
   text-decoration: none;
 }
 
link:hover {
   text-decoration: none;
   
   
 }
 
link:active {
   text-decoration: none;
   
 }


.option{
   
   background-repeat: no-repeat;
   position: relative;
   min-width:60px;
   max-width: 1000px;
   // margin:10px;
   margin-left: 1rem;
   margin-right: 1rem;
   background:var(--optionBackground, var(--defaultBackground, #E6E9ED));
   overflow:hidden;
   background-size:auto 120%;
   background-position:center;
   
   cursor: pointer;
   transition:.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
   &:not(.active) {
      flex-grow:1;
      
      border-radius:5rem;
      .shadow {
         
         box-shadow: rgba(0, 0, 0, 0.45) 0px -10rem 10rem -1rem inset;
         
      }
      
   }
   
   .shadow {
            
            bottom:0px;
            left:0px;
            right:0px;
            
            height:100%;
            
            transition:.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
   }
   
  
   .label{

      position:absolute;
      left:50%;
      top:75%;

      transform: translate(-50%,-50%);
      
      >div{
         text-align: center;
      }
      .main{
         // font-family: 'Roboto', sans-serif;
         font-weight: bold;
         font-size: large;
         color: white;
      }
      .sub{
         // font-family: 'Roboto', sans-serif;
         font-size: medium;
         color: white;
      }
   }

   
}

.option:hover{
   box-shadow: rgba(0, 0, 0, 0.55) 0px 3rem 3rem;
   transform: scale(1.09);
   
   .shadow{
      box-shadow: rgba(0, 0, 0, 0.75) 0px -20rem 10rem -1rem inset;;
   }
}

.option:active{
   box-shadow: rgba(243, 216, 175, 0.788) 0px 3rem 3rem;
}

@keyframes slideInLeft {
   0% {
     transform: translateX(-100%);
   }
   100% {
     transform: translateX(0);
   }
 
}




body {
   background: linear-gradient(180deg, #396189 0%, #43A2B1 99.48%) no-repeat center center fixed;
   margin: 0;
   caret-color: transparent;
   

   
   
   font-family: 'Montserrat','Roboto', sans-serif;
   transition:.25s;
}

.homePageWrapper{
   display:flex;
   
   justify-content:center;
   align-content: center;
   align-items:center;
   flex-wrap:wrap;
   height:100vh;
}

.options{
   display:flex;
   




   min-width:60rem;
   max-width:120rem;
   width:calc(100% - 100px);
   
   height:75%;

   @for $i from 1 through 6 {
      @media screen and (max-width:900px - $i*40) {
         transition:.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
         min-width:900px - $i*40;
         // .option:nth-child(#{6-$i}) {
         //    display:none;
         // }
      }
   }
}

@media (max-width: 900px) {

   .options{
      display:flex;
      flex-direction:column;
      flex-wrap:nowrap;

      .option{
         margin: 1rem;
      }
      

      @for $i from 1 through 10 {
         transition:.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
         @media screen and (max-width:1000px - $i*40) {
            min-width:800px - $i*45;
            .logo{
               transition:.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
               height: 10rem-$i*0.35rem;
            

            }
            .label{
               position:absolute;
               left:50%;
               top:40%;

               transform: translate(-50%,-50%);
            }
            
         }
      }
      
   }
  
}

@for $i from 0 to 10 {
   @media screen and (min-width:1100px + $i*40) {
      .options{
         min-width: 60rem + $i*3.5rem;
         transition:.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
         .logo{
            height: 10rem+ $i*0.5rem;
            transition:.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
         }
         .info{
            top: 75% - $i*3%;
            .main{
            font-size:1.12rem+$i*0.04rem;
            }
            .sub{
               font-size:1rem+$i*0.02rem;
            }
         }
      }
      
      
      

   }
   
}

.logo{
   height: 10rem;
   transform: translate(-0.5rem,1rem);
   
}

body::after {
   content: "© 2023 Abrar Patel";
   position: absolute;
   
   bottom: auto;
   left: 0;
   right: 0;
   margin-top: 5%;
   text-align: center;
   font-family: 'Montserrat','Roboto', sans-serif;
   font-weight: lighter;
   font-size:medium;
   color: white;
 }